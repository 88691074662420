<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-md-right mb-4">
              <router-link :to="{ name: 'agency.payment-methods.create' }" class="btn btn-primary btn-sm mr-4">
                <i class="uil uil-plus"></i> New Payment Method
              </router-link>
            </div>
            <template v-if="!loadingMethods">
              <div class="table-responsive mb-0">
                <b-table v-if="user" :items="paymentMethods" :fields="datatable.columns">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>

                  <template v-slot:cell(last_four)="data">
                    ********* {{ data.item.last_four }}
                  </template>
                  <template v-slot:cell(default)="data">
                    <feather v-if="data.item.id === user.default_payment_method" type="check"
                      class="icon-dual-success" />
                  </template>
                  <template v-slot:cell(actions)="data">
                    <router-link v-b-tooltip.hover :to="{
                      name: 'agency.payment-methods.edit',
                      params: { id: data.item.id },
                    }" title="Edit" class="btn btn-success btn-sm">
                      <i class="uil uil-edit"></i>
                    </router-link>
                    <b-button v-if="data.item.id !== user.default_payment_method" v-b-tooltip.hover variant="primary"
                      size="sm" title="Set Default" class="ml-1" :disabled="processing === data.item.id"
                      @click="setDefault(data.item)">
                      <b-spinner v-if="processing === data.item.id" small />
                      <i v-else class="uil uil-check"></i>
                    </b-button>
                    <b-button v-if="data.item.id !== user.default_payment_method" v-b-tooltip.hover variant="danger"
                      size="sm" title="Delete" class="ml-1" :disabled="processing === data.item.id"
                      @click="onRemove(data.item)">
                      <b-spinner v-if="processing === data.item.id" small />
                      <i v-else class="uil uil-trash"></i>
                    </b-button>

                    <b-button v-if="data.item.id === user.default_payment_method" v-b-tooltip.hover variant="primary"
                      size="sm" title="Verify" class="ml-1" :disabled="processing === data.item.id"
                      @click="setDefault(data.item)">
                      <b-spinner v-if="processing === data.item.id" small />
                      <i v-else class="uil uil-shield-check"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Remove Payment Method">
      <p v-if="selectedPaymentMethod">Are you sure you want to remove this payment method <b>*** {{
        selectedPaymentMethod.last_four
      }}</b>?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false">Cancel</b-button>
        <b-button variant="danger" @click="remove">
          <b-spinner v-if="processing" small />
          <span v-else>
            <i class="uil uil-trash"></i> Delete
          </span></b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'brand' },
          { key: 'last_four', label: 'Card number' },
          { key: 'default' },
          { key: 'actions' },
        ],
      },
      loadingMethods: false,
      processing: null,
      showDeleteModal: false,
      selectedPaymentMethod: null,
    }
  },

  computed: {
    paymentMethods() {
      return this.$store.getters['paymentMethod/all'] || []
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.getPaymentMethods()
  },

  methods: {
    onRemove(item) {
      this.selectedPaymentMethod = item
      this.showDeleteModal = true
    },
    getPaymentMethods() {
      this.loadingMethods = true

      this.$store
        .dispatch('paymentMethod/getAll')
        .then((res) => {
          this.loadingMethods = false
        })
        .catch(() => {
          this.loadingMethods = false
        })
    },

    remove() {
      this.processing = this.selectedPaymentMethod.id

      this.$store
        .dispatch('paymentMethod/remove', this.selectedPaymentMethod.id)
        .then((res) => {
          this.processing = null
          this.showDeleteModal = false
        })
        .catch(() => {
          this.processing = null
        })
    },

    setDefault(paymentMethod) {
      this.processing = paymentMethod.id

      this.$store
        .dispatch('paymentMethod/setDefault', paymentMethod.id)
        .then((res) => {
          this.processing = null
        })
        .catch(() => {
          this.processing = null
        })
    },
  },
}
</script>
